import React from 'react';
import PropTypes from 'prop-types';
import * as s from "../../styles/globalStyles";
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
`

const Title = styled.h1`
  font-size: 24px;
  margin: 10px;
`

const Description = styled.p`
  font-size: 18px;
`

const Link = styled.a`
  font-size: 14px;
  margin: 10px;
`

export const StyledImg = styled.img`
  width: 200px;
  @media (min-width: 400px) {
    width: 300px;
  }
  @media (min-width: 800px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Gate = ({ children }) => {
  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        image= "/config/images/bg.png"
        style={{ 
          padding: 24,
          
        }}
      >
        <ResponsiveWrapper flex={1} style={{ padding: 12 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"Halfie Right NFT"} src={"/config/images/halfies.gif"} />
          </s.Container>
          
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 24,
              borderRadius: 8,
            }}
          >
            <s.Container ai={"center"} jc={"center"} fd={"row"}>

            <StyledLink target={"_blank"} href={"http://www.gomotoys.com/"}>  
            <StyledImg alt={"Halfie NFT Logo"} src={"/config/images/gomotoys.png"} 
            style={{
              height: 40,
              width: 180,
              padding: 2,
            }}
            />
             </StyledLink>

            <s.SpacerMedium />

            <StyledLink target={"_blank"} href={"https://twitter.com/gomotoys"}>
            <StyledImg alt={"Twitter Link"} src={"/config/images/twitter.png"} 
            style={{
              padding: 2,
              height: 42,
              width: 42,
            }}
            />
            </StyledLink>

            <s.SpacerXSmall />

            <StyledLink target={"_blank"} href={"https://opensea.io/collection/halfies"}>
            <StyledImg alt={"Opensea Link"} src={"/config/images/opensea.png"} 
            style={{
              padding: 2,
              height: 42,
              width: 42,
            }}
            />
            </StyledLink>

            <s.SpacerXSmall />

            <StyledLink target={"_blank"} href={"https://etherscan.io/address/0xc6d065b226F3A6562d940905eB956Bdea915d570"}>
            <StyledImg alt={"Etherscan Link"} src={"/config/images/etherscan.png"} 
            style={{
              padding: 2,
              height: 42,
              width: 42,
            }}
            />
            </StyledLink>
            </s.Container>
            
            <s.SpacerMedium />
            <StyledImg alt={"Halfie NFT Logo"} src={"/config/images/halfies.png"} />
    
            <s.SpacerSmall/>
              <div>
                 {children}
              </div>
            <s.SpacerLarge/>
            <s.TextDescription
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                <p>Halfies are a collection of 666 unique generated NFTs.<br></br>They first came to life in 2012 in resin form and now they comeback in the virtual world.<br></br> 66 Ancestors, 600 Descendants.</p>
                <br></br><p>Each Halfie has 6 unique views. Top, Bottom, Left, Right, Front and Back!</p>
        </s.TextDescription>
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"Halfie Left NFT"}
              src={"/config/images/halfies.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

Gate.propTypes = {
  children: PropTypes.any
};

export default Gate;
